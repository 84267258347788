<template>
    <div class="row">
      <div class="col-md-12">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <form @submit.prevent="filterResult">
              <div class="row align-items-end py-0 my-0 mx-0">
                <div class="col-xl-11 col-lg-11 col-md-11 col-sm-12 my-2 row px-0 mx-0">
                  <input-filter
                      :helper-text="$t('general.search')"
                      title="general.search"
                      :placeholder="$t('general.search_filter')"
                      :model.sync="search"
                  ></input-filter>
                </div>
                <div
                    class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                    style="margin: 0 !important;">
                  <div class="col-12 pr-0">
                    <button @click="filterResult(null)"
                            class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                        $t('general.filter')
                      }}
                    </button>
                  </div>
                  <div class="col-12 pr-0">
                    <button @click="resetFilters"
                            class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                      {{ $t('general.clear') }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  
      <div class="col-md-12">
        <dashboard-box class="pb-10">
          <template v-slot:preview>
            <data-table
                class="relative"
                tableVariant="''"
                :hover="false"
                :outlined="false"
                :striped="false"
                :no-border-collapse="true"
                :borderless="true"
                :bordered="false"
                @onClickEdit="onClickEdit"
                @hitBottom="onHitBottom"
                :items="formattedItem"
                :fields="fields"
                :per-page.sync="per_page"
                :page.sync="page"
            ></data-table>
            <div class="d-flex justify-content-end items-end fixed cursor-pointer" style="right: 10px; bottom: 10px;">
              <div class="w-60px h-60px" v-if="isUserGranted('InvoiceCustomApprovalStatus', ['create'],false,true)"
                   @click="$router.push({name: 'management.packing-list-statuses.create'})">
                <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                    class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                  })">
                  </span>
              </div>
            </div>
          </template>
        </dashboard-box>
      </div>
    </div>
  </template>
  
  <script>
  import DashboardBox from "@/assets/components/DashboardBox";
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import DataTable from "@/assets/components/dataTable/DataTable";
  import moment from 'moment';
  import {LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
  import InputFilter from "@/assets/components/filters/InputFilter";
  import SelectFilter from "@/assets/components/filters/SelectFilter";
  
  import {
    BASE_URL,
    ERROR,
    ITEMS,
    LOADING,
    DELETE_ITEM_BY_ID,
    SUCCESS,
    SET_ITEMS,
    HANDLE_INFINITE_SCROLL
  } from "@/core/services/store/packing-list-statuses/packing_list_statuses.module";
  import store from "@/core/services/store";
  
  
  export default {
    name: "PackingListIndex",
    components: {
      SelectFilter,
      InputFilter,
      DataTable,
      DashboardBox
    },
    computed: {
      error() {
        return store.getters[ERROR];
      },
      loading() {
        return store.getters[LOADING];
      },
      success() {
        return store.getters[SUCCESS];
      },
      items() {
        return store.getters[ITEMS];
      },
      filters() {
        return {
          per_page: this.per_page,
          page: this.page,
          search: this.search,
          type_id:5
        }
      },
      formattedItem() {
        let results = [];
        for(let key in this.items){
          let currentItem = this.items[key];
          let createdAt = currentItem.created_at ? moment(currentItem.created_at, LARAVEL_DATE_TIME_FORMAT) : moment();
            results.push({
              id: currentItem.id,
              name: this.getValueByDottedKey(currentItem, 'translations.0.name'),
              createdAt: createdAt.format(MOMENT_SYSTEM_DATE_FORMAT),
            })
        }
        return results;
      }
    },
    data() {
      return {
        page: 1,
        per_page: 25,
        search: '',
        state: null,
        languages: [],
        editRouteName: 'management.invoice_custom_approval_statuses.edit',
        createRouteName: 'management.invoice_custom_approval_statuses.create',
        fields: [
          {
            key: 'id',
            label: this.$t('general.id'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'name',
            label: this.$t('general.name'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'createdAt',
            label: this.$t('general.created_at'),
            sortable: false,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
          {
            key: 'only_update',
            label: this.$t('general.actions'),
            sortable: false,
            class: 'text-center border border-top-0 border-right-0 font-size-lg'
          },
        ],
      };
    },
    methods: {
      onHitBottom($state){
        if(this.loading) {
          return;
        }
        if ($state) {
          this.state = $state;
        }
        this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
          filters: this.filters,
          url: BASE_URL,
          '$state': $state,
        }).then(result => {
          if(result.status && $state) {
            $state.loaded();
          }else{
            if('end' in result && $state){
              $state.complete();
            }
          }
        })
      },
      onClickDelete(id) {
        let self = this;
        this.sweetAlertConfirm(this.$t("general.are_you_sure")).then(result => {
          if(result) {
            let payload = {
              url: BASE_URL + '/' + id,
              id: id,
            }
            self.$store.dispatch(DELETE_ITEM_BY_ID, payload)
          }
        })
      },
      onClickEdit(id) {
        if(this.isUserGranted('InvoiceCustomApprovalStatus', ['update'],true,true)){
          this.$router.push({name: this.editRouteName, params: {id: id}})
        }
      },
      filterResult() {
        let self = this;
        this.page = 1;
        this.filters.page = 1;
        this.onHitBottom();
        setTimeout(() => {
          self.state.reset();
        }, 100);
      },
      resetFilters() {
        this.search = '';
      }
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("general.dashboard"), route: "dashboard"},
        {title: this.$t("menu.management.packing_list_stasuses")}
      ]);
  
  
      this.$store.commit(SET_ITEMS, null);
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  